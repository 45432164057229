import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TransitionLeft } from '../../../helpers/utils'
import { apiFetch } from '../../../service/apiFetch'
import { Zoom, Grid, CircularProgress } from '@mui/material';
import AboutProspect from './InformationItem/AboutProspect'
import Specialities from './InformationItem/Specialities'
import TargetAudiences from './InformationItem/TargetAudiences'

export default function Information({
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    darkState,
    zoom,
    phoneResponsive,
    setProspect,
    prospect,
}) {
    const { id } = useParams()
    let navigate = useNavigate()
    const mounted = useRef(false);

    useEffect(() => {
        (async () => {
            mounted.current = true
            const prospectFetch = await apiFetch(`/prospects/${id}`)
            if (prospectFetch && prospectFetch.uuid && mounted.current) {
                setProspect(prospectFetch)
            } else {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/prospects')
            }
            return () => mounted.current = false
        })()
    }, [setProspect, setTransition, setText, setSeverity, setOpenSnackbar, id, navigate])

    return (
        <>
            {prospect ? <Zoom in={zoom}>
                    <div style={phoneResponsive ? {maxWidth: 215} : {}}>
                        <Grid container spacing={3} style={{marginTop: -38}}>
                            <Grid item xs={12} sm={6}>
                                <AboutProspect prospect={prospect} darkState={darkState} zoom={zoom} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Specialities prospect={prospect} darkState={darkState} zoom={zoom} />
                                <TargetAudiences prospect={prospect} darkState={darkState} zoom={zoom} />
                            </Grid>
                        </Grid>
                    </div>
                </Zoom> :
                <div style={{textAlign: 'center'}}>
                    <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                </div>}
        </>
    )
}
