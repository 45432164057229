import React, { useEffect }  from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Checkbox,
    TextField,
    AppBar,
    Tabs,
    Tab,
    Box,
    FormHelperText,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';
import {apiFetch} from "../../service/apiFetch";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExportDocumentPopup({
    loading,
    open,
    handleClose,
    darkState,
    handleAccept,
    specialists,
    specialist,
    setSpecialist,
    documentTypes,
    setDocumentTypes,
    setDocumentType,
    error 
}) {
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        (async () => {
            const getDocumentType = await apiFetch('/document_types')
            if (getDocumentType && getDocumentType['hydra:member']) {
                setDocumentTypes(getDocumentType['hydra:member'])
            }
        })()
    }, [setDocumentTypes])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const allSpecialists = [
        { firstName: 'Tous les spécialistes', isAll: true },
        ...specialists
    ];

    const handleSpecialistChange = (event, newValue) => {
        // Sélectionner tous les spécialistes ou sélectionner les spécialistes manuellement
        if (newValue.some(item => item.isAll)) {
            setSpecialist(allSpecialists);
        } else {
            setSpecialist(newValue);
        }
    };

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="export-popup-title"
            aria-describedby="export-popup-description"
            style={{marginBottom: 100}}
        >
            <DialogTitle id="export-popup-title">{"Exporter des documents"}</DialogTitle>
            <DialogContent>
                <div style={{flexGrow: 1, backgroundColor: !darkState ? 'white' : '#161d31'}}>
                    <AppBar position="static" color={darkState ? 'secondary' : "primary"}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Autocomplete
                            multiple
                            id="multiple-checkbox"
                            options={allSpecialists}
                            disableCloseOnSelect
                            getOptionLabel={(option) => {
                                if (option.isAll) {
                                    return option.firstName;
                                }
                                return option.lastName
                                    ? `${option.lastName.toUpperCase()} ${option.firstName.charAt(0).toUpperCase()}${option.firstName.slice(1).toLowerCase()}`
                                    : option.firstName;
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {option.isAll
                                        ? option.firstName
                                        : `${option.lastName.toUpperCase()} ${option.firstName.charAt(0).toUpperCase()}${option.firstName.slice(1).toLowerCase()}`}
                                </li>
                            )}
                            onChange={handleSpecialistChange}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    color={darkState ? 'secondary' : 'primary'}
                                    label="Liste des spécialistes *"
                                    placeholder="Spécialiste(s) *" 
                                />
                            )}
                        />
                        <FormHelperText style={{color: 'red'}}>
                            {error.specialist}
                        </FormHelperText>
                        {specialist && specialist.length > 0 && <Autocomplete
                            multiple
                            id="multiple-checkbox"
                            options={documentTypes}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => {
                                return option.name === value.name
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {option.name}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                setDocumentType(newValue);
                            }}
                            style={{ width: 500, marginTop: 20 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    color={darkState ? 'secondary' : 'primary'}
                                    label="Liste des types de document"
                                    placeholder="type(s)"
                                />
                            )}
                        />}
                    </TabPanel>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Télécharger
                </Button>
            </DialogActions>
        </Dialog>
    );
}