import React, { useState } from 'react';
import {List, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Grid, TextField, Button} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

export default function MultipleAddressProspect({darkState, officeAddresses, setOfficeAddresses, disable = false, matches}) {

    const [newAddress, setNewAddress] = useState('');

    // Permet l'ajout d'une adresse de cabinet (2 maximum)
    const handleAddAddress = () => {
        if (newAddress.trim() !== '') {
            if (!officeAddresses.some(item => item.address === newAddress)) {
                setOfficeAddresses(prevAddresses => [
                    ...prevAddresses,
                    { address: newAddress }
                ]);
            }
            setNewAddress('');
        }
    };

    // Permet la suppression d'une adresse de cabinet
    const handleDeleteAddress = (addressToDelete) => {
        setOfficeAddresses(prevAddresses =>
            prevAddresses.filter(item =>
                typeof item === 'string'
                    ? item !== addressToDelete
                    : item.address !== addressToDelete
            )
        );
    };

    return (
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
                <TextField
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                    label="Adresse(s) cabinet(s)"
                    variant="outlined"
                    fullWidth
                    size="small"
                    color={darkState ? 'secondary' : 'primary'}
                    sx={{
                        width: 300,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    onClick={handleAddAddress}
                    color="primary"
                    variant="contained"
                    sx={{
                        width: 300,
                    }}
                    disabled={!newAddress.trim() || officeAddresses.length >= 2}
                >
                    Ajouter l'adresse
                </Button>
            </Grid>

            <Grid item xs={12}>
            </Grid>
            <Grid item xs={3} md={5} style={{marginTop: -20}}>
                <div>
                    {officeAddresses.length > 0 && <List>
                        {officeAddresses.map((address, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    style={matches ? {minWidth: 50} : {}}
                                    primary={typeof address === 'string' ? address : address.address}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        disabled={disable}
                                        color="inherit"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDeleteAddress(typeof address === 'string' ? address : address.address)}                                        style={matches ? {marginRight: '-48px', marginTop: 25} : {}}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                    }
                </div>
            </Grid>
        </Grid>
    )
}