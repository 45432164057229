import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_PROSPECT = 'FETCHING_PROSPECT'
const SET_PROSPECT = 'SET_PROSPECT'
const ADD_PROSPECT = 'ADD_PROSPECT'
const UPDATE_PROSPECT = 'UPDATE_PROSPECT'
const DELETE_PROSPECT = 'DELETE_PROSPECT'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_PROSPECT:
            return { ...state, loading: true }
        case SET_PROSPECT:
            return {...state, prospects: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_PROSPECT:
            return {...state, prospects: [action.payload, ...state.prospects]}
        case UPDATE_PROSPECT:
            return {...state, prospects: action.payload }
        case DELETE_PROSPECT:
            return {...state, prospects: state.prospects.filter((i) => {
                    return i.uuid !== action.payload
                })}
        default:
            return state
    }
}

export function useProspects() {
    const [state, dispatch] = useReducer(reducer, {
        prospects: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        prospects: state.prospects,
        totalItems: state.totalItems,
        view: state.view,
        fetchProspects: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.prospects || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_PROSPECT' })
            const prospects = await apiFetch(route || '/prospects', {method: 'GET'})

            if (isSubscribed().current && prospects && prospects['hydra:member']) {
                dispatch({type: 'SET_PROSPECT', payload: prospects})
            }
            return prospects && prospects['hydra:member']
        },
        updateProspect: async function(prospectId, data, forceResponse) {
            const updatedProspect = await apiFetch('/prospects/' + prospectId, {
                method: 'PATCH',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedProspect.violations && !updatedProspect['hydra:description']) {
                dispatch({type: 'UPDATE_PROSPECT', payload: updatedProspect })
            }
            return updatedProspect
        },
        deleteProspect: async function (prospectId) {
            const deletedProspect = await apiFetch('/prospects/' + prospectId, {
                method: 'DELETE'
            }, true)
            if (deletedProspect && deletedProspect.ok) {
                dispatch({type: 'DELETE_PROSPECT', payload: prospectId})
            }
            return deletedProspect
        },
    }
}