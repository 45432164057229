import { Grid, TextField, FormControl, MenuItem, InputLabel, Select, Paper, Checkbox } from '@mui/material';
import React from 'react'
import MultipleAddressProspect from "../../components/MultipleAddressProspect";
import ListItemText from "@mui/material/ListItemText";

export default function Information({
    error,
    handleChangeSpecialistType,
    handleChangeTaxStatus,
    lastName,
    setLastName,
    firstName,
    setFirstName,
    specialistType,
    specialities = [],
    setSpecialities,
    targetAudiences = [],
    setTargetAudiences,
    taxStatus,
    siret,
    setSiret,
    rpps,
    setRpps,
    officeAddresses,
    setOfficeAddresses,
    urssafAddress,
    setUrssafAddress,
    mobilePro,
    setMobilePro,
    email,
    setEmail,
    darkState,
}) {
    const allSpecialitiesOptions = [
        "Prévention des risques psycho-sociaux", "Travail", "Souffrance au travail",
        "Harcèlement", "Deuil", "Trauma", "Santé", "Addictions", "Handicap",
        "Conflit (professionnel)", "Coaching"
    ];

    const allTargetAudienceOptions = [
        "Enfants", "Adolescents", "Adultes", "Couples", "Familles", "Aidants familiaux"
    ];

    const handleSpecialitiesChange = (event) => {
        const value = event.target.value;
        setSpecialities(Array.isArray(value) ? value : [value]);
    };

    const handleTargetAudiencesChange = (event) => {
        const value = event.target.value;
        setTargetAudiences(Array.isArray(value) ? value : [value]);
    };

    return (
        <Paper sx={{mt: 3, pl: 3}} >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={lastName}
                        onChange={(event) => {
                            setLastName(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.lastName}
                        helperText={error.lastName}
                        id="lastName"
                        name="lastName"
                        label="Nom"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br/>

                    <TextField
                        value={firstName}
                        onChange={(event) => {
                            setFirstName(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.firstName}
                        helperText={error.firstName}
                        id="firstName"
                        name="firstName"
                        label="Prénom"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br/>

                    <FormControl
                        error={!!error.specialistType}
                        required
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    >
                        <InputLabel id="select-label">Spécialisation</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={specialistType}
                            label="Specialisation"
                            onChange={handleChangeSpecialistType}
                        >
                            <MenuItem value="">
                                <em>Sélectionner</em>
                            </MenuItem>
                            <MenuItem value={'Clinique'}>Clinique</MenuItem>
                            <MenuItem value={'Travail'}>Travail</MenuItem>
                        </Select>
                    </FormControl><br/>

                    <FormControl color={darkState ? 'secondary' : "primary"} sx={{ width: 300, mb: 3 }}>
                        <InputLabel>Spécialité(s)</InputLabel>
                        <Select
                            multiple
                            value={Array.isArray(specialities) ? specialities : []}
                            onChange={handleSpecialitiesChange}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allSpecialitiesOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={specialities.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl><br />

                    <FormControl color={darkState ? 'secondary' : "primary"} sx={{ width: 300, mb: 3 }}>
                        <InputLabel>Public(s) ciblé(s)</InputLabel>
                        <Select
                            multiple
                            value={Array.isArray(targetAudiences) ? targetAudiences : []}
                            onChange={handleTargetAudiencesChange}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allTargetAudienceOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={targetAudiences.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl><br />

                    <FormControl
                        error={!!error.taxStatus}
                        required
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    >
                        <InputLabel id="select-label">Statut fiscal</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={taxStatus}
                            label="Statut fiscal"
                            onChange={handleChangeTaxStatus}
                        >
                            <MenuItem value="">
                                <em>Sélectionner</em>
                            </MenuItem>
                            <MenuItem value={'Micro-entreprise'}>Micro-entreprise</MenuItem>
                            <MenuItem value={'BNC'}>BNC</MenuItem>
                            <MenuItem value={'Société'}>Société</MenuItem>
                            <MenuItem value={'Portage salarial'}>Portage salarial</MenuItem>
                        </Select>
                    </FormControl><br/>
                </Grid>
                <Grid item xs={12} sm={6}>

                    <TextField
                        value={siret}
                        onChange={(event) => {
                            setSiret(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        error={!!error.siret}
                        helperText={error.siret}
                        id="siret"
                        name="siret"
                        label="Siret"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />

                    <TextField
                        value={rpps}
                        onChange={(event) => {
                            setRpps(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        error={!!error.rpps}
                        helperText={error.rpps}
                        id="rpps"
                        name="rpps"
                        label="N° rpps"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />

                    <MultipleAddressProspect
                        darkState={darkState}
                        officeAddresses={officeAddresses}
                        setOfficeAddresses={setOfficeAddresses}
                    />

                    <TextField
                        value={urssafAddress}
                        onChange={(event) => {
                            setUrssafAddress(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        error={!!error.urssafAddress}
                        helperText={error.urssafAddress}
                        id="urssafAddress"
                        name="urssafAddress"
                        label="Adresse URSSAF"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />

                    <TextField
                        value={mobilePro}
                        onChange={(event) => {
                            setMobilePro(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.mobilePro}
                        helperText={error.mobilePro}
                        id="mobilePro"
                        name="mobilePro"
                        label="N° mobile pro"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />

                    <TextField
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.email}
                        helperText={error.email}
                        id="email"
                        name="email"
                        label="Adresse mail"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                </Grid>
            </Grid>
        </Paper>
    )
}