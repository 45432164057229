import React, { useState, useEffect } from 'react'
import ProspectCard from './ProspectListItem/CardView/ProspectCard'
import { Grid, Zoom, Typography, CircularProgress, Paper } from '@mui/material/';
import { useProspects } from '../hooks/prospects';
import {TransitionLeft} from '../helpers/utils';
import {Waypoint} from "react-waypoint";
import {TextField, Tooltip} from "@mui/material";

let debounce = null

export default function ProspectList({
    phoneResponsive,
    darkState,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    handleToggle,
    handleCloseBackdrop,
}) {
    const [firstNameOut, setFirstNameOut] = useState('')
    const [allProspects, setAllProspects] = useState([])
    const [loading, setLoading] = useState(false)
    const [spinner, setSpinner] = useState(false)
    document.title = 'Codex - Liste des prospects'

    const {
        view,
        prospects,
        fetchProspects,
        deleteProspect
    } = useProspects()

    useEffect(() => {
        (async () => {
            setLoading(true);
            const prosptectsFetch = await fetchProspects(`/prospects`)
            setAllProspects(prosptectsFetch)
            setLoading(false);
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDelete = async (prospect) => {
        handleToggle()
        const deletedProspect = await deleteProspect(prospect.uuid)

        if (deletedProspect && deletedProspect.ok) {
            setText('Le prospect a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedProspect && deletedProspect['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        handleCloseBackdrop()
    }

    const enterHandler = async () => {
        setSpinner(true)
        if (view) {
            const prospects = await fetchProspects(view['hydra:next'])
            if (prospects) {
                setAllProspects((prospects && allProspects.concat(prospects)))
            }
        }
        setSpinner(false)
    };

    const handleSearch = async (e) => {
        clearTimeout(debounce)
        setFirstNameOut(e.target.value)
        if (e.target.value.length > 2) {
            debounce = setTimeout(async () => {
                setLoading(true)
                const prospectsFetch = await fetchProspects(`/prospects?fullName=${e.target.value}`)
                setAllProspects(prospectsFetch)
                setLoading(false)
            }, 500)
        } else if (e.target.value.length === 0) {
            setLoading(true)
            const prospectsFetch = await fetchProspects(`/prospects`)
            setAllProspects(prospectsFetch)
            setLoading(false)
        }
    }

    return (
        <Zoom in={zoom}>
            <div>
                <Paper
                    style={phoneResponsive ? {} : {padding: 15, paddingLeft: 30, marginBottom: 30, marginTop: 0}}
                    sx={{
                        marginBottom: 30,
                        padding: '10px 20px',
                        display: 'flex',
                    }}
                >
                    <div style={{flex: '1 1 100%', marginTop: 10}}>
                        <Typography
                            style={{flex: '1 1 100%'}}
                            color={darkState ? 'secondary' : 'primary'}
                            sx={{
                                alignItems: 'center',
                                fontSize: 24,
                                mt: 1,
                                mr: {sm: 10, xs: 2},
                            }}
                        >
                            Liste des prospects
                        </Typography>
                    </div>
                    <div>
                        <Tooltip TransitionComponent={Zoom} title={'minimum 3 lettres'}>
                            <TextField
                                style={phoneResponsive ? {width: 215} : {width: 300}}
                                color={darkState ? 'secondary' : 'primary'}
                                label='Nom/Prénom'
                                id='firstNameOut'
                                name='firstNameOut'
                                onChange={handleSearch}
                                value={firstNameOut}
                            />
                        </Tooltip>
                    </div>
                </Paper>
                <Grid
                    container
                    spacing={ prospects && prospects.length ? 6 : 0}
                    style={
                        prospects && prospects.length ? phoneResponsive ? {
                            maxWidth: "320px",
                                maxHeight: "600px"
                        } :
                            {} : {
                            marginTop: 0,
                            marginLeft: 0,
                        }
                }
                >
                    {!loading && allProspects && allProspects.length ?
                            allProspects.map((prospect, index) => (
                                <Grid key={index} item xs={12} sm={3}>
                                    {index === allProspects.length - 1 &&
                                        <Waypoint onEnter={() => enterHandler(index)}/>}
                                    <ProspectCard
                                        setAllProspects={setAllProspects}
                                        fetchProspects={fetchProspects}
                                        handleDelete={handleDelete}
                                        darkState={darkState}
                                        prospect={prospect}
                                        setText={setText}
                                        setTransition={setTransition}
                                        setOpenSnackbar={setOpenSnackbar}
                                        setSeverity={setSeverity}
                                    />
                                </Grid>
                            )) : !loading &&
                            <Typography style={{marginTop: 100, marginLeft: 100}}>
                                Pas de prospect disponible
                            </Typography>
                    }
                </Grid>
                {(spinner || (loading && firstNameOut.length > 2)) &&
                <div style={{textAlign: 'center', marginTop: 30}}>
                    <CircularProgress color={darkState ? 'secondary' : 'primary'}/>
                </div>}
            </div>
        </Zoom>
    )
}
