import React, { useState } from 'react';
import { Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { progressBarWidth, TransitionLeft } from '../../helpers/utils';
import { ADMIN, SUPER_ADMIN } from '../../constantes/mainConstantes';
import { handleFormError } from '../../service/security/formError';
import ProgressBar from '../../components/ProgressBar';
import UploadFile from '../../components/UploadItem';
import { apiFetch } from '../../service/apiFetch';
import UploadPdf from '../../components/UploadPdf';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUser({
    user,
    setOpenCreateUser,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    open,
    handleClose,
    darkState,
    createUser 
}) {
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState('')
    const [pcFunction, setPcFunction] = useState('')
    const [pcDivision, setPcDivision] = useState('')
    const [error, setError] = useState(false)
    const [style, setStyle] = useState(false)
    const [photo, setPhoto] = useState(false);
    const [reachable, setReachable] = React.useState(false);
    const [portrait, setPortrait] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData()
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto
            }, true)
        }
        if (createPhoto && createPhoto['hydra:description']) {
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let createPortrait = null
        if (portrait) {
            let dataPortrait = new FormData()
            dataPortrait.append('file', portrait && portrait[0])
            createPortrait = await apiFetch('/user_portrait_objects', {
                method: 'POST',
                body: dataPortrait
            }, true)
        }
        if (createPortrait && createPortrait['hydra:description']) {
            setText(createPortrait['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let data = new FormData(e.target)
        const email = data.get('email')
        const pass = data.get('password')
        const firstName = data.get('firstName')
        const lastName = data.get('lastName')
        const phone = data.get('phone')
        const description = data.get('description')
        const userRole = role
        data = user.roles[0] === SUPER_ADMIN ? 
        {
            email: email,
            password: pass,
            firstName: firstName,
            lastName: lastName,
            roles: [userRole],
            phone: phone,
            reachable: reachable,
            function: pcFunction,
            division: pcDivision,
            description: description,
        } :
        { 
            email: email,
            password: pass,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            function: pcFunction,
            division: pcDivision,
            reachable: reachable,
            description: description
        }

        if (photo) {
            data.photo = createPhoto['@id']
        }

        if (portrait) {
            data.portrait = createPortrait['@id']
        }

        const createdUser = await createUser(data)

        if (createdUser && createdUser.uuid) {
            setText('L\'utilisateur a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenCreateUser(false)
            setRole('')
            document.querySelector("#email").value = ''
            document.querySelector("#password").value = ''
            document.querySelector("#firstName").value = ''
            document.querySelector("#lastName").value = ''
            document.querySelector("#phone").value = ''
            document.querySelector("#description").value = ''
            setStyle(false)
        } else if (createdUser.violations) {
            const errorField = handleFormError(createdUser, ['password', 'roles', 'firstName', 'lastName', 'email', 'phone', 'description', 'funtion'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdUser && createdUser['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleChangeRole = (e) => {
        setRole(e.target.value)
    }

    const handleChangeFunction = (e) => {
        setPcFunction(e.target.value)
    }

    const handleChangeDivision = (e) => {
        setPcDivision(e.target.value)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleChange = (event) => {
        setReachable(event.target.checked);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <form noValidate onSubmit={handleCreate} autoComplete="off">
                    <DialogTitle id="title">{"Ajouter un utilisateur"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description" component='div'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.firstName}
                                        helperText={error.firstName}
                                        id="lastName"
                                        name="lastName"
                                        label="Nom"
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                            mt: 1,
                                        }}
                                    /><br />
                                    <TextField
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.lastName}
                                        helperText={error.lastName}
                                        id="firstName"
                                        name="firstName"
                                        label="Prénom"
                                        sx={{
                                            width: 300,
                                            mb: 3
                                        }}
                                    /><br />
                                    {user.roles && (user.roles[0] === SUPER_ADMIN || user.roles[0] === ADMIN) &&
                                    <FormControl color={darkState ? 'secondary' : "primary"} error={!!error.roles}>
                                        <InputLabel htmlFor="age-native-simple">Rôle</InputLabel>
                                        <Select
                                            value={role}
                                            onChange={handleChangeRole}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-native-simple',
                                            }}
                                            sx={{
                                                width: 300,
                                            }}
                                        >
                                            <MenuItem aria-label="None" value="" ><em>Sélectionner un rôle</em></MenuItem>
                                            <MenuItem value={'ROLE_USER'}>Rôle Utilisateur</MenuItem>
                                            {user.roles[0] === SUPER_ADMIN && <MenuItem value={'ROLE_ADMIN'}>Rôle Admin</MenuItem>}
                                            <MenuItem value={'ROLE_INTERVENTION_MANAGER'}>Rôle responsable des interventions</MenuItem>
                                            <MenuItem value={'ROLE_SPECIALIST_MANAGER'}>Rôle responsable des spécialistes</MenuItem>
                                        </Select>
                                    </FormControl>}
                                    <FormHelperText sx={{mb: 3, color: '#f44336', ml: 2}}>{error.roles}</FormHelperText>
                                    <TextField
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.phone}
                                        helperText={error.phone}
                                        id="phone"
                                        name="phone"
                                        label="Téléphone"
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    /><br />
                                    <div style={{marginTop: 30}}>
                                        <UploadPdf
                                            setPdf={setPortrait}
                                            title={'Ajouter un portrait'}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.email}
                                        helperText={error.email}
                                        id="email"
                                        name="email"
                                        label="Email"
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                            mt: 1,
                                        }}
                                    /><br />
                                    <TextField
                                        id="password"
                                        name="password"
                                        color={darkState ? 'secondary' : "primary"}
                                        label="Mot de passe"
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        color='inherit'
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        style={{width: 300}}
                                        error={!!error.password} helperText={error.password}
                                        onChange={handleChangeProgressBar}
                                    />
                                    <ProgressBar style={style} customStyle={{width: 300, marginTop: 4, marginLeft: 0}} /><br />
                                    <FormControl color={darkState ? 'secondary' : "primary"}>
                                        <InputLabel htmlFor="function-simple">Fonction</InputLabel>
                                        <Select
                                            value={pcFunction}
                                            onChange={handleChangeFunction}
                                            inputProps={{
                                                name: 'function',
                                                id: 'function-simple',
                                            }}
                                            sx={{
                                                width: 300,
                                                mb: 3,
                                            }}
                                        >
                                            <MenuItem aria-label="None" value="" ><em>Sélectionner une fonction</em></MenuItem>
                                            <MenuItem value={'Directrice'}>Directrice</MenuItem>
                                            <MenuItem value={'Directrice Administrative'}>Directrice Administrative</MenuItem>
                                            <MenuItem value={'Responsable Communication et Cheffe de projets'}>
                                                Responsable Communication et Cheffe de projets
                                            </MenuItem>
                                            <MenuItem value={'Responsable Developpement Informatique'}>
                                                Responsable Developpement Informatique
                                            </MenuItem>
                                            <MenuItem value={'Responsable Grands Comptes publics'}>
                                                Responsable Grands Comptes publics
                                            </MenuItem>
                                            <MenuItem value={'Responsable Transition Numérique'}>
                                                Responsable Transition Numérique
                                            </MenuItem>
                                            <MenuItem value={'Responsable Pédagogique'}>Responsable Pédagogique</MenuItem>
                                            <MenuItem value={'Responsable Exploitation'}>Responsable Exploitation</MenuItem>
                                            <MenuItem value={'Développeur Informatique'}>Développeur Informatique</MenuItem>
                                            <MenuItem value={'Développeur Mobile'}>Développeur Mobile</MenuItem>
                                            <MenuItem value={'Chargé(e) de Projets Intervention'}>Chargé(e) de Projets Intervention</MenuItem>
                                            <MenuItem value={'Chargé(e) d\'Affaires'}>Chargé(e) d'Affaires</MenuItem>
                                            <MenuItem value={'Chargé(e) de Projets Ligne d\'écoute'}>
                                                Chargé(e) de Projets Ligne d'écoute
                                            </MenuItem>
                                            <MenuItem value={'Assistante Administrative'}>Assistante Administrative</MenuItem>
                                            <MenuItem value={'Assistante Pédagogique'}>Assistante Pédagogique</MenuItem>
                                            <MenuItem value={'Assistante de Communication'}>Assistante de Communication</MenuItem>
                                            <MenuItem value={'Assistante Projets'}>Assistante Projets</MenuItem>
                                            <MenuItem value={'Assistante administrative MP/AO'}>Assistante administrative MP/AO</MenuItem>
                                            <MenuItem value={'Assistante de Gestion facturation'}>Assistante de Gestion facturation</MenuItem>
                                            <MenuItem value={'Coordinateur Intervention'}>Coordinateur Intervention</MenuItem>
                                            <MenuItem value={'Graphiste'}>Graphiste</MenuItem>
                                            <MenuItem value={'Comptable'}>Comptable</MenuItem>
                                            <MenuItem value={'Chargée du Développement et de l\'Animation du réseau des psychologues'}>
                                                Chargée du Développement et de l'Animation du réseau des psychologues
                                            </MenuItem>
                                            <MenuItem value={'Gestionnaire Coaching Digital'}>Gestionnaire Coaching Digital</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{marginTop: 10}} color={darkState ? 'secondary' : "primary"} error={!!error.function}>
                                        <InputLabel htmlFor="division-simple">Pôle</InputLabel>
                                        <Select
                                            value={pcDivision}
                                            onChange={handleChangeDivision}
                                            inputProps={{
                                                name: 'division',
                                                id: 'division-simple',
                                            }}
                                            sx={{
                                                width: 300,
                                                mb: 3,
                                            }}
                                        >
                                            <MenuItem aria-label="None" value="" ><em>Sélectionner un pôle</em></MenuItem>
                                            <MenuItem value={'Direction'}>Direction</MenuItem>
                                            <MenuItem value={'Accueil'}>Accueil</MenuItem>
                                            <MenuItem value={'Exploitation'}>Exploitation</MenuItem>
                                            <MenuItem value={'Interventions / Audits'}>Interventions / Audits</MenuItem>
                                            <MenuItem value={'Formation'}>Formation</MenuItem>
                                            <MenuItem value={'Commercial'}>Commercial</MenuItem>
                                            <MenuItem value={'Ligne d\'écoute'}>Ligne d'écoute</MenuItem>
                                            <MenuItem value={'Communication'}>Communication</MenuItem>
                                            <MenuItem value={'Réseau psy / recrutement'}>Réseau psy / recrutement</MenuItem>
                                            <MenuItem value={'Projets'}>Projets</MenuItem>
                                            <MenuItem value={'Informatique'}>Informatique</MenuItem>
                                            <MenuItem value={'Appels d\'offre'}>Appels d'offre</MenuItem>
                                            <MenuItem value={'Comptabilité'}>Comptabilité</MenuItem>
                                            <MenuItem value={'Wellmonday'}>Wellmonday</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div style={{marginTop: 50}}>
                                        <UploadFile setPhoto={setPhoto} title={'Ajouter une photo'} />
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={reachable}
                                                onChange={handleChange}
                                                name="reachable"
                                                color={darkState ? 'secondary' : "primary"}
                                            />
                                        }
                                        label="Joignable"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color={darkState ? 'secondary' : "primary"}>
                            Annuler
                        </Button>
                        <Button disabled={loading} color={darkState ? 'secondary' : "primary"} type='submit'>
                            Enregistrer
                        </Button>
                    </DialogActions>
                </form>
                {loading && <LinearProgress color={darkState ? 'secondary' : "primary"} />}
            </Dialog>
        </div>
    );
}