import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

class MapView extends Component {
  constructor() {
    super();
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapLoaded: false,
      newMapZoom: false,
      users: {},
      style: {
        height: "800px",
        width: "100%"
      }
    };
    this.handleMapIdle = this.handleMapIdle.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.dashboard) {
      this.setState({style: {
        height: "432px",
        width: "100%"
      }})
    }

    document.title = 'Codex - Liste des utilisateurs'
    if (this.props.specialists) {
        this.setState({users: this.props.specialists['hydra:member']})
    }
  }

  handleZoomChanged = (props, map) => {
    this.state.users.map((user) => {
      if (user.hasCutomIcon) {
        if (this.props.mapZoom > map.zoom) {
          user.latitude = user.latitude + this.props.getLatLngOffset(map.zoom, true).lat
          user.longitude = user.longitude - this.props.getLatLngOffset(map.zoom, true).lng
        } else {
          user.latitude = user.latitude - this.props.getLatLngOffset(map.zoom, true).lat * 2
          user.longitude = user.longitude + this.props.getLatLngOffset(map.zoom, true).lng * 2
        }
      }
      return null
    })
    this.props.setMapZoom && this.props.setMapZoom(map.zoom)
    this.newMapZoom = map.zoom
  }

  onMarkerClick = (props, marker, e, user) => {
    this.props.setMapZoom && props.map && this.props.setMapZoom(props.map.zoom)
    if (user && this.props.intervention) {
      user.isSelected = user.isSelected ? undefined : true
      if (user.isSelected) {
        this.props.setSelectedSpecialists([...this.props.selectedSpecialists, user])
      } else if (!user.isSelected) {
        this.props.setSelectedSpecialists(this.props.selectedSpecialists.filter((spe) => spe.uuid !== user.uuid))
      }
      if (!user.hasCutomIcon) {
        user.latitude = user.isSelected ? user.latitude + this.props.getLatLngOffset(props.map.zoom).lat : user.latitude - this.props.getLatLngOffset(props.map.zoom).lat
        user.longitude = user.isSelected ? user.longitude - this.props.getLatLngOffset(props.map.zoom).lng : user.longitude + this.props.getLatLngOffset(props.map.zoom).lng
      }

      this.setState(prevState => ({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: user.isSelected && true
      }));
  } else {
      this.setState(prevState => ({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      }));
    }
    if (document.getElementsByClassName('gm-style-iw-t').length) {
      document.getElementsByClassName('gm-style-iw-t')[0].setAttribute("style","margin-top: -55px;max-width:250px;")
    }
  };

  onClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerMounted = element => {
    if (element) {
    this.onMarkerClick(element.props, element.marker, element);
    }
  };

  handleMapIdle = () => {
    this.setState({
      mapLoaded: true
    });
  };

  getLatLngOffset = (zoom) => {
    switch(this.newMapZoom || zoom) {
        case 0:
            return {lat: 28.52, lng: 34}
        case 1:
            return {lat: 17.76, lng: 16.92}
        case 2:
            return {lat: 9.88, lng: 7.96}
        case 3:
            return {lat: 4.88, lng: 4.48}
        case 4:
            return {lat: 2.72, lng: 2.24}
        case 5:
            return {lat: 1.36, lng: 1.12}
        case 6:
            return {lat: 0.68, lng: 0.56}
        case 7:
            return {lat: 0.34, lng: 0.28}
        case 8:
            return {lat: 0.125, lng: 0.035}
        case 9:
            return {lat: 0.085, lng: 0.065}
        case 10:
            return {lat: 0.0425, lng: 0.033}
        case 11:
            return {lat: 0.0213, lng: 0.0165}
        case 12:
            return {lat: 0.0106, lng: 0.00825}
        case 13:
            return {lat: 0.0053, lng: 0.0041}
        case 14:
            return {lat: 0.0027, lng: 0.00205}
        case 15:
            return {lat: 0.0013, lng: 0.001025}
        case 16:
            return {lat: 0.0007, lng: 0.0005125}
        case 17:
            return {lat: 0.0003, lng: 0.00025625}
        default: 
            return {lat: 0, lng: 0}
    }
}

  render() {

    var selectedIcon = {
      path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "blue",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };

    var waitingIcon = {
      path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "orange",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };

    var refusedIcon = {
      path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "red",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };
    
    var acceptedIcon = {
      path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "green",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };

    var interventionIcon = {
      path: "M17.592,8.936l-6.531-6.534c-0.593-0.631-0.751-0.245-0.751,0.056l0.002,2.999L5.427,9.075H2.491c-0.839,0-0.162,0.901-0.311,0.752l3.683,3.678l-3.081,3.108c-0.17,0.171-0.17,0.449,0,0.62c0.169,0.17,0.448,0.17,0.618,0l3.098-3.093l3.675,3.685c-0.099-0.099,0.773,0.474,0.773-0.296v-2.965l3.601-4.872l2.734-0.005C17.73,9.688,18.326,9.669,17.592,8.936 M3.534,9.904h1.906l4.659,4.66v1.906L3.534,9.904z M10.522,13.717L6.287,9.48l4.325-3.124l3.088,3.124L10.522,13.717z M14.335,8.845l-3.177-3.177V3.762l5.083,5.083H14.335z",
      fillColor: "#12047D",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };


    return (
        <div id='mapBox' style={{boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'}}>
          <Map
            google={this.props.google}
            style={this.state.style}
            initialCenter={this.props.newCoordonnate ? { lat: this.props.newCoordonnate.lat, lng: this.props.newCoordonnate.lng} : this.props.intervention ? { lat: this.props.intervention.address.latitude, lng: this.props.intervention.address.longitude} :  this.props.dashboard ? {lat: 46.866667, lng: 2.333333} : {lat: 48.866667, lng: 2.333333}}
            zoom={this.props.newCoordonnate ? 8 : this.props.intervention ? 8 : 6}
            onIdle={this.handleMapIdle}
            center={this.props.mapCenter}
            onZoomChanged={this.handleZoomChanged}
          >
            {this.state.mapLoaded && this.state.users && this.state.users.length && this.state.users.map((user) => (
                <Marker
                  ref={this.onMarkerMounted}
                  onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, user)}
                  name={user.firstName}
                  title={`${user.firstName} ${user.lastName}`}
                  specialist={user}
                  id={user.uuid}
                  key={user.id}
                  position={user.address && {lat: user.address.latitude, lng: user.address.longitude}}
                  icon={user.isSelected ? selectedIcon : user.hasAccepted ? acceptedIcon : user.hasRefused ? refusedIcon : user.isWaiting ? waitingIcon : ''}
                />
            ))}
            {this.state.mapLoaded && this.props.isFromGeneralMap && this.state.users && this.state.users.length && this.state.users.map((user) => {
                return user.offices && user.offices.length && user.offices.map((office) => (
                  <Marker
                    ref={this.onMarkerMounted}
                    onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, user)}
                    name={user.firstName}
                    title={`${user.firstName} ${user.lastName}`}
                    specialist={user}
                    office={office}
                    id={user.uuid}
                    key={user.id}
                    position={office.address && {lat: office.address.latitude, lng: office.address.longitude}}
                  />
            ))})}

            {this.props.intervention && <Marker
                  ref={this.onMarkerMounted}
                  position={{lat: this.props.intervention.address.latitude  + this.props.getLatLngOffset(this.props.mapZoom).lat, lng: this.props.intervention.address.longitude - this.props.getLatLngOffset(this.props.mapZoom).lng}}
                  icon={interventionIcon}
            />}
            {this.props.newCoordonnate && <Marker
              ref={this.onMarkerMounted}
              position={{lat: this.props.newCoordonnate.lat + this.getLatLngOffset(this.mapZoom || 8).lat, lng: this.props.newCoordonnate.lng - this.getLatLngOffset(this.mapZoom || 8).lng}}
              icon={interventionIcon}
            />}
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
              setZIndex={99999999999}
            >
              {Object.keys(this.state.selectedPlace).length && this.state.selectedPlace.specialist ? <div style={{color: 'black'}}>
                <h6 style={{fontSize: 15, marginTop: 0, marginBottom: 0}}>{this.state.selectedPlace.title}</h6>
                {this.state.selectedPlace.office && <h6 style={{fontSize: 12, marginTop: 0, marginBottom: 0}}>Ce marquer est une adresse de cabinet</h6>}
                <p>Pour voir la fiche complète cliquer <a target='_blank' rel="noreferrer" href={`/specialists/${this.state.selectedPlace.id}`}>ici</a></p>
                {this.state.selectedPlace.specialist.address && <p><span style={{color: '#12047D', fontWeight: 'bold'}}>Adresse : </span>{this.state.selectedPlace.specialist.address.fullAddress}</p>}
                {this.state.selectedPlace.office && <p><span style={{color: '#12047D', fontWeight: 'bold'}}>Adresse du cabinet: </span>{this.state.selectedPlace.office.address.fullAddress}</p>}
                <p><span style={{color: '#12047D', fontWeight: 'bold'}}>N° mobile pro:</span> {this.state.selectedPlace.specialist.mobilePro}</p>
                {this.state.selectedPlace.specialist.phone && <p><span style={{color: '#12047D', fontWeight: 'bold'}}>N° fixe:</span> {this.state.selectedPlace.specialist.phone}</p>}
                <p><span style={{color: '#12047D', fontWeight: 'bold'}}>Email : </span>{this.state.selectedPlace.specialist.email}</p>
              </div> : <div></div>}
            </InfoWindow>
          </Map>
        </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapView);
