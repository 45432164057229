import React from 'react'
import { Paper, Zoom, Typography } from '@mui/material';

export default function AboutProspect({ prospect, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'}
                            style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>À propos</span>
                </Typography>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Prospect : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.lastName && prospect.firstName
                            ? `${prospect.lastName.toUpperCase()} ${prospect.firstName.charAt(0).toUpperCase() + prospect.firstName.slice(1).toLowerCase()}`
                            : prospect.lastName
                                ? prospect.lastName.toUpperCase()
                                : prospect.firstName
                                    ? prospect.firstName.charAt(0).toUpperCase() + prospect.firstName.slice(1).toLowerCase()
                                    : 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adresse mail : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.email || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Spécialisation : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.specialistType || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Statut fiscal : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {(prospect.taxStatus) || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>RPPS : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.rpps || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Siret : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.siret || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'} style={{display: 'flex'}}>
                        <span style={{fontWeight: 'bold'}}>Adresse(s) cabinet(s) : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.officeAddresses.length
                            ? prospect.officeAddresses.map((address, index) => (
                                <div key={index}>
                                    <span> - {typeof address === 'string' ? address : address.address}</span>
                                    <br/>
                                </div>
                            ))
                            : 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adresse URSAFF : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.urssafAddress || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>N° mobile pro : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {prospect.mobilePro || 'Non renseigné'}
                    </Typography>
                </div>
            </Paper>
        </Zoom>
    )
}
