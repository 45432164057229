import React, { useState } from 'react'
import { Zoom, Paper, Button, ButtonGroup } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Information from "./ShowProspectListItem/Information";
import {useNavigate} from "react-router-dom";

export default function ShowProspect({
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    darkState,
    zoom,
    user,
    phoneResponsive,
}) {
    const [prospect, setProspect] = useState(false)

    let navigate = useNavigate()
    const handleModify = () => {
        navigate(`/prospects/${prospect.uuid}/edit`)
    }

    const handleBack = () => {
        navigate(`/prospects`)
    }

    return (
        <Zoom in={zoom}>
            <div>
                <Paper style={{padding: 15, paddingLeft: 30, marginBottom: 10, marginTop: 0}}>
                    <Button
                        startIcon={<InfoIcon />}
                        variant='contained'
                        color={darkState ? 'secondary': "primary"}
                        style={{marginRight: 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)'}}
                    >
                        Informations Générales
                    </Button>

                    <ButtonGroup
                        style={{position: 'absolute', right: 40}}
                        variant="contained"
                        color={darkState ? 'secondary': "primary"}
                        aria-label="contained primary button group"
                    >
                         <Button onClick={handleModify}>Modifier</Button>
                        <Button onClick={handleBack}>Retour</Button>
                    </ButtonGroup>
                </Paper>
                <Information
                        prospect={prospect}
                        setProspect={setProspect}
                        phoneResponsive={phoneResponsive}
                        darkState={darkState}
                        zoom={zoom}
                        user={user}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                    />
            </div>
        </Zoom>
    )
}
